import React, { createRoot } from 'react-dom/client';
import OverviewMap from './OverviewMap';
import FieldMap from './FieldMap';
import EditableFieldMap from './EditableFieldMap';
import SiteFormMap from './SiteFormMap';
import StoragesFormMap from './StoragesFormMap';
import ActivitiesEditableMap from './Activities/EditableMap';
import NutrientApplicationReportSubForm from './NutrientApplicationReportSubForm';
import OperationFarmSubForm from './OperationFarmSubForm';
import WeatherStationFormMap from './WeatherStationFormMap';
import FieldMapWithPinEdit from './FieldMapWithPinEdit';
import DistSalesPrescriptionMap from './DistSalesPrescriptionMap';
import ActivityDetailCard from './ActivityDetailCard';
import FieldWorkerSubForm from './FieldWorkerSubForm';
import ActivitiesReadOnlyMap from './Activities/ReadOnlyMap'
import "leaflet/dist/leaflet.css";

function renderMap() {
  const overviewElement = document.getElementById('overview-map');
  if (overviewElement) {
    const data = JSON.parse(overviewElement.getAttribute('data-features'))
    const root = createRoot(overviewElement);
    if (data.features?.length) root.render(<OverviewMap data={data} />);
  }

  const siteFormElement = document.getElementById('site-form-map');
  if (siteFormElement) {
    const data = JSON.parse(siteFormElement.getAttribute('data-storage-geo-json'))
    const root = createRoot(siteFormElement);
    if (data.features?.length) root.render(<SiteFormMap data={data} />);
  }

  const activitiesDetailCardElement = document.getElementById('activity-details-card');
  if (activitiesDetailCardElement) {
    const data = JSON.parse(activitiesDetailCardElement.getAttribute('data-activity'))
    const jobPlanData = JSON.parse(activitiesDetailCardElement.getAttribute('data-job-plan'))
    const plannedData = JSON.parse(activitiesDetailCardElement.getAttribute('data-planned-activities'))
    const client = activitiesDetailCardElement.getAttribute('data-client')
    const activityType = activitiesDetailCardElement.getAttribute('data-activity-type')
    const farm = activitiesDetailCardElement.getAttribute('data-farm')
    const field = activitiesDetailCardElement.getAttribute('data-field')
    const application_type = activitiesDetailCardElement.getAttribute('data-application-type')
    createRoot(activitiesDetailCardElement).render(
      <ActivityDetailCard
        data={{
          ...data,
          planned_activities: plannedData,
          job_plan: jobPlanData,
          client,
          farm,
          field,
          application_type,
          activity_type: activityType
        }}
      />
    );
  }

  const wsFormElement = document.getElementById('ws-form-map');
  if (wsFormElement) {
    const dataStr = wsFormElement.getAttribute('data-gps')
    const root = createRoot(wsFormElement);
    root.render(<WeatherStationFormMap gpsData={dataStr} />)
  }

  const storagesFormElement = document.getElementById('storages-form-map');
  if (storagesFormElement) {
    const gpsData = JSON.parse(storagesFormElement.getAttribute('data-gps'))
    const root = createRoot(storagesFormElement);
    root.render(<StoragesFormMap gpsData={gpsData} />);
  }
  
  const fieldElement = document.getElementById('field-map');
  if (fieldElement) {
    let fieldOutline; 
    let legalBounds;
    try {
      fieldOutline = JSON.parse(fieldElement.getAttribute('data-field'))
      legalBounds = JSON.parse(fieldElement.getAttribute('data-legal-bounds'))
    } catch {}

    const root = createRoot(fieldElement);
    root.render(<FieldMap fieldOutline={fieldOutline} legalBounds={legalBounds} />);
  }

  const multiFieldMapElements = document.getElementsByClassName('multi-field-map');
  if (multiFieldMapElements?.length) {
    
    for (let e of multiFieldMapElements) {
      let fieldOutline; 
      try {

        fieldOutline = JSON.parse(e.getAttribute('data-field-outline'))
      } catch {}

      const root = createRoot(e);
      root.render(<FieldMap fieldOutline={fieldOutline} />);
    }
  }
  
  const distSaleFieldMapElements = document.getElementsByClassName('dist-sale-presc-field-map');
  if (distSaleFieldMapElements?.length) {
    for (let e of distSaleFieldMapElements) {
      let recordIndex
      let recordTimestamp
      try {
        recordTimestamp =  e.previousSibling.previousSibling.value;
        recordIndex = JSON.parse(e.getAttribute('data-record-index'));
      } catch {}
      const geoJSON = JSON.parse(e.getAttribute('data-geo-json'));
      const ind = (typeof recordIndex == "undefined") ? recordTimestamp : recordIndex

      const existingNode = $(`#${ind}`).prop('nodeName');
      if (!existingNode) {
        const root = createRoot(e);
        root.render(<DistSalesPrescriptionMap recordIndex={ind} initialGeoJson={geoJSON} />);
      }
    }
  }

  const fieldWithPinElement = document.getElementById('field-map-with-pin-edit');
  if (fieldWithPinElement) {
    const root = createRoot(fieldWithPinElement);
    root.render(
      <FieldMapWithPinEdit
        fieldGeoJson={JSON.parse(fieldWithPinElement.getAttribute('data-field'))}
        id={fieldWithPinElement.getAttribute('data-id')}
        toClass={fieldWithPinElement.getAttribute('data-to-class')}
      />
    );
  }

  const editableFieldMapElement = document.getElementById('editable-field-map');
  if (editableFieldMapElement) {
    const field_json = JSON.parse(editableFieldMapElement.getAttribute('data-field'))
    const root = createRoot(editableFieldMapElement);
    root.render(<EditableFieldMap field={field_json} />);
  }

  const activitiesReadOnlyElement = document.getElementById('activities-readonly-map');
  if (activitiesReadOnlyElement) {
    let fieldOutline; 
    let prescribedActivityOutline;
    try {
      fieldOutline = JSON.parse(activitiesReadOnlyElement.getAttribute('data-field-outline'))
      prescribedActivityOutline = JSON.parse(activitiesReadOnlyElement.getAttribute('data-prescribed-activity-outline'))
    } catch {}

    const activitySheets = JSON.parse(activitiesReadOnlyElement.getAttribute('data-activity-sheets'))
    const root = createRoot(activitiesReadOnlyElement);
    root.render(
      <ActivitiesReadOnlyMap
        fieldOutline={fieldOutline}
        prescribedActivityOutline={prescribedActivityOutline}
        activitySheets={activitySheets}
      />
    );
  }

  const activitiesElement = document.getElementById('activities-map');
  if (activitiesElement) {
    let fieldOutline; 
    let prescribedOutline;
    let distSaleOutline;
    try {
      fieldOutline = JSON.parse(activitiesElement.getAttribute('data-field-outline'))
      prescribedOutline = JSON.parse(activitiesElement.getAttribute('data-prescribed-outline'))
      distSaleOutline = JSON.parse(activitiesElement.getAttribute('data-distribution-sale-outline'))
    } catch {}

    const activityId = JSON.parse(activitiesElement.getAttribute('data-activity-id'))
    const activitySheets = JSON.parse(activitiesElement.getAttribute('data-activity-sheets'))
    const root = createRoot(activitiesElement);
    root.render(
      <ActivitiesEditableMap
        fieldOutline={fieldOutline} 
        prescribedOutline={prescribedOutline}
        distSaleOutline={distSaleOutline}
        activityId={activityId}
        activitySheets={activitySheets}
      />
    );
  }

  const activityApplicationsElement = document.getElementById('field-worker-application-sub-form');
  if (activityApplicationsElement) {
    const activityId = JSON.parse(activityApplicationsElement.getAttribute('data-activity-id'))
    const processingAsAppliedData = JSON.parse(activityApplicationsElement.getAttribute('data-processing'))
    const fieldGeoJson = JSON.parse(activityApplicationsElement.getAttribute('data-field'))
    const plannedGeoJson = JSON.parse(activityApplicationsElement.getAttribute('data-planned-geo-json'))
    const ind = JSON.parse(activityApplicationsElement.getAttribute('data-application-index'))
    const geoJson = JSON.parse(activityApplicationsElement.getAttribute('data-geo-json'))
    const root = createRoot(activityApplicationsElement);

    root.render(
      <FieldWorkerSubForm
        activityId={activityId}
        processingAsAppliedData={processingAsAppliedData}
        initialGeoJson={geoJson}
        fieldGeoJson={fieldGeoJson} 
        plannedGeoJson={plannedGeoJson}
        index={ind}
      />
    );
  }

  const nuReportSubForm = document.getElementById('nutrient-app-rep-sub-form');
  if (nuReportSubForm) {
    const root = createRoot(nuReportSubForm);
    const data = JSON.parse(nuReportSubForm.getAttribute('data-actual-activity-options'))
    root.render(<NutrientApplicationReportSubForm actualActivityOptions={data} />);
  }

  const siteFarmSubForm = document.getElementById('operation-farm-sub-form');
  if (siteFarmSubForm) {
    const root = createRoot(siteFarmSubForm);
    const sites = JSON.parse(siteFarmSubForm.getAttribute('data-site-options'))
    const farms = JSON.parse(siteFarmSubForm.getAttribute('data-farm-options'))
    const farmId = JSON.parse(siteFarmSubForm.getAttribute('data-farm-id'))
    const siteId = JSON.parse(siteFarmSubForm.getAttribute('data-site-id'))
    root.render(<OperationFarmSubForm farms={farms} sites={sites} farmId={farmId} siteId={siteId} />);
  }
}

window.renderMap = renderMap
renderMap()